.slick-arrow {
  
  background-color: var(--color_bg_slick_arrow);
  border: 1px solid var(--color_border_slick_arrow);
  color: var(--color_slick_arrow);
  
}
.slick-arrow:not(.slick-disabled):hover {
  
    
      background: var(--background_1);
      color: var(--color_1);
      border-color: var(--border_1);
    
  
}
.widget-title .box-title {
  color: var(--page_title_color);
  position: relative;
  
    
      letter-spacing: .05em;
    

    
      font-size: 20px;
    

    
      font-weight: 700;
    

    
    
      text-transform: uppercase;
    
    
    margin-bottom: 15px;
  
}















.loading-modal {background-color: rgba(255,255,255,.8)}
.ajax-lookbook-modal .lookbook-content .product-item .btn,
.ajax-lookbook-modal .lookbook-content .product-item .product-shop .shopify-payment-button button.shopify-payment-button__button,
.product-shop .shopify-payment-button .ajax-lookbook-modal .lookbook-content .product-item button.shopify-payment-button__button,
.ajax-lookbook-modal .lookbook-content .product-item .product-shop .groups-btn .wishlist,
.product-shop .groups-btn .ajax-lookbook-modal .lookbook-content .product-item .wishlist,
.ajax-lookbook-modal .lookbook-content .product-item .tabs__product-page .spr-container .spr-summary-actions-newreview,
.tabs__product-page .spr-container .ajax-lookbook-modal .lookbook-content .product-item .spr-summary-actions-newreview,
.ajax-lookbook-modal .lookbook-content .product-item .addresses-page .btn-edit-delete a,
.addresses-page .btn-edit-delete .ajax-lookbook-modal .lookbook-content .product-item a {background: rgba(35, 35, 35, 0.9);color:var(--color_1);border-color: rgba(35, 35, 35, 0.9)}
.grid-item-mansory .countdown-grid-item {background-color: rgba(233, 81, 68, 0.8)}
.cart-form .cart-list .qty-group input[name="quantity"][disabled] {background-color: rgba(193, 193, 193, 0.5)}
.swatch.swatch_size_large .swatch-element:not(.color) label {
  
    min-width: 70px;height: 70px;border-radius: 50%;line-height: 58px;
  
}
.swatch.swatch_size_large .swatch-element.color label {
  width: 70px;height: 70px;border-radius: 50%;
}
.tabs__product-page .spr-container .spr-review-reportreview {color: rgba(233, 81, 68, 0.8)}
.cart-template .wrapper-cart-template .cart-list .group-action .remove .text-hover:before {background-color: rgba(35, 35, 35, 0.5)}
.custom-acc-page .last a:last-child {border-bottom:1px solid rgba(60, 60, 60, 0.7)}
.body-boxed {background-color: #f8f8f8}
.home-blog-style2 .article-img:before {background-color: rgba(35, 35, 35, 0.5)}
#cart_slider {background-color: #edebf7;color: #3c3c3c}
.adaptive_height img.lazyload:after,
.adaptive_height img.lazyloading:after {background-color: #f6f8f9}
.newsletter-toolbar .social-icons li:hover a {color: #505050!important}
.newsletter-toolbar .social-icons a {color: #a7a7a7}
.newsletter-toolbar .toolbar--text span {color: #202020}
.newsletter-toolbar .toolbar--text:hover span {color: #202020}


.product-title,
.product-name {overflow: hidden;white-space: normal;display: -webkit-box;-webkit-line-clamp: 2;text-overflow: ellipsis;-webkit-box-orient: vertical}









.page-header, .btn, .product-shop .product-title,
.page-header h1, .btn h1, .product-shop .product-title h1, .page-header h2, .btn h2, .product-shop .product-title h2 {font-family: var(--fonts_name_2)}
.compare-link a {padding: 8px 18px 6px;text-transform: uppercase}
.recently-viewed-products .widget-title,
.related-products .widget-title {text-align: left}


.page-header h2,
.page-header h1 {margin-top: 20px}



.products-list .adaptive_height img {position: static;transition: none;}
.products-grid .grid-item:not(.grid-item-mansory) .product-image {overflow: hidden}
.products-grid .grid-item:not(.grid-item-mansory) .product-image img {position: absolute !important;top: 50%;left: 50%;transform: translate(-50%, -50%);object-fit: contain;height: 100%;max-height: 100%;width: 100%;max-width: 100%;
}
.products-grid .grid-item:not(.grid-item-mansory) .product-grid-image {position: relative}
.products-grid .grid-item:not(.grid-item-mansory) .product-grid-image:after {content: "";display: block;width: 100%;height: 0;padding-top: 130%}

.sidebar-widget-product .products-grid .grid-item .product-grid-image:after {padding-top: 160px}
.quickSearchResultsWrap .product-item .product-image .product-grid-image:after {padding-top: 160px}


.product-item .product-bottom .wrapper-compare {
  justify-content: center;
}

.product-item .wrapper-compare .compare-button {background-color: transparent;position: relative;top: -2px}



.product-item .product-bottom .wrapper-compare .compare-button .compare-text {
  font-size: var(--font_size);display: block;
}



.sticky_form {position: fixed;bottom: 0;left: 0;right: 0;background-color: #ffffff;color: #3c3c3c;z-index: 100;padding: 7px 0;-webkit-transition: 0.5s ease;-moz-transition: 0.5s ease;-ms-transition: 0.5s ease;-o-transition: 0.5s ease;transition: 0.5s ease;-moz-transform: translate3d(0, 100%, 0);-o-transform: translate3d(0, 100%, 0);-ms-transform: translate3d(0, 100%, 0);-webkit-transform: translate3d(0, 100%, 0);transform: translate3d(0, 100%, 0);opacity: 0;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";filter: alpha(opacity=0);-webkit-box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.1);-moz-box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.1);box-shadow: 0 -3px 3px -3px rgba(0, 0, 0, 0.1)}
.sticky_form .sticky_icon {display: inline-block;font-size: 18px;line-height: 25px;position: relative;top: 1px;margin-right: 8px}
.sticky_form .align-items-center {-webkit-box-pack: space-between;-moz-box-pack: space-between;-ms-flex-pack: space-between;-webkit-justify-content: space-between;justify-content: space-between}
.sticky_form .sticky-item {margin: 0px 20px}
.sticky_form .countdown-item {display: inline-flex;font-size: var(--font_size);font-weight: 500;letter-spacing: 0.05em;text-transform: uppercase;line-height: var(--font_size_plus13)}
.sticky_form .clock-item .num {padding-left: 5px}
.sticky_form .clock-item span:not(.num) {padding-right: 5px}
.sticky_form .clock-item:first-of-type .num {padding-left: 0}
.sticky_form .clock-item:last-of-type span:not(.num) {padding-right: 0}
.sticky_form .pr-img {display: -webkit-box;display: -webkit-flex;display: -moz-flex;display: -ms-flexbox;display: flex;-webkit-align-items: center;-moz-align-items: center;-ms-align-items: center;align-items: center}
.sticky_form .pr-img img {width: 45px}
.sticky_form .pr-img .product-title {margin: 0 0 0 17px}
.sticky_form .sticky_group {display: -webkit-box;display: -webkit-flex;display: -moz-flex;display: -ms-flexbox;display: flex;margin: 0 -10px;max-width: 312px}
.sticky_form .sticky_group .pr-selectors, .sticky_form .sticky_group .quantity {padding: 0 10px}
.sticky_form .action {text-align: right}
.sticky_form .sticky-add-to-cart {background: var(--background_1);color: var(--color_1);border-color: var(--border_1);width: 100%;min-width: 200px;max-width: 200px}
.sticky_form .sticky-add-to-cart:hover {background: var(--background_2);color: var(--color_2);border-color: var(--border_2)}
.sticky_form .quantity {-webkit-box-flex: 0;-ms-flex: 0 0 110px;flex: 0 0 110px;max-width: 110px}
.sticky_form .pr-selectors {position: relative;-webkit-box-flex: 0;-ms-flex: 0 0 calc(100% - 90px);flex: 0 0 calc(100% - 90px);max-width: calc(100% - 90px)}
.sticky_form .pr-selectors .pr-active {position: relative;max-width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;border: 1px solid var(--border_dropdown);display: block;padding: 8px 30px 6px 13px;color: #646464;cursor: pointer;background: #ffffff}
.sticky_form .pr-selectors .pr-active:before {right: 10px;position: absolute;top: 50%;content: "\f107";font: normal normal normal 18px/1 FontAwesome;transform: translateY(-50%)}
.sticky_form .pr-selectors ul {padding: 0;list-style: none;position: absolute;top: auto;width: -moz-calc(100% - 20px);width: -webkit-calc(100% - 20px);width: -ms-calc(100% - 20px);width: calc(100% - 20px);bottom: -moz-calc(100% - 1px);bottom: -webkit-calc(100% - 1px);bottom: -ms-calc(100% - 1px);bottom: calc(100% - 1px);background: #ffffff;margin-bottom: 0;border: 1px solid var(--border_dropdown);overflow: auto;height: 0;opacity: 0;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";filter: alpha(opacity=0);-webkit-transition: all 0.3s ease;-moz-transition: all 0.3s ease;-ms-transition: all 0.3s ease;-o-transition: all 0.3s ease;transition: all 0.3s ease}
.sticky_form .pr-selectors ul li a {display: block;padding: 5px 0;color: #646464}
.sticky_form .pr-selectors ul li a:hover, .sticky_form .pr-selectors ul li a.active {color: #5572ad}
.sticky_form .pr-selectors ul li .sold-out {color: #b6b6b6;text-decoration: line-through !important;pointer-events: none}
.sticky_form.open-sticky .pr-selectors ul {opacity: 1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";filter: alpha(opacity=100);max-height: 290px;padding: 10px;height: inherit}
.layout_style_surfup .sticky_form.open-sticky .pr-selectors ul {height: auto;}
.show_sticky .sticky_form {-moz-transform: translate3d(0, 0, 0);-o-transform: translate3d(0, 0, 0);-ms-transform: translate3d(0, 0, 0);-webkit-transform: translate3d(0, 0, 0);transform: translate3d(0, 0, 0);opacity: 1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";filter: alpha(opacity=100)}


.wrapper-wishlist .product-vendor {display: none!important}


.hot_stock {margin-bottom: 15px;color: #d62828}






@media (max-width: 1024px) {
  .style_product_grid_2 .product-item .product-bottom .wrapper-size {
        
            justify-content: center
        
    }
}
@media (min-width: 480px) {
  .icon-nav.menu-open {
    
      right: inherit;
      left: 337px;
    
  }
}
@media (max-width: 767px) {
    

  .featured-products.has-banner.layout_style_2 .column-product .column-product-inner .grid-item .product-item .wrapper-vendor {
    
            justify-content: center;
        
  }
  .style_product_grid_2 .product-item .product-bottom .wrapper-size, .style_product_grid_2 .product-item .product-bottom .wrapper-compare, .style_product_grid_2 .product-item .product-bottom .wrapper-vendor, .featured-products.has-banner.layout_style_2 .column-product .column-product-inner .grid-item .product-item .wrapper-vendor {
    
          justify-content: center;
      
  }
  
  .recently-viewed-products-sidebar {
      display: none;
  }
  
  
  
  .recently-viewed-products-sidebar {
      display: none;
  }
  
}
@media (max-width: 1199px) {
  

  .navigation-05 .lang-currency-groups .lang-block,
    .navigation-05 .lang-currency-groups .currency-block {
        border-bottom:1px solid #ebebeb
    }
    .header-bottom {
        background-color: #ffffff;
        color: #232323;
    }
    .header-bottom a {
        color: #232323
    }
    .wrapper-navigation .header-pc {
      color: #3c3c3c
    }
    .wrapper-navigation .header-pc .lang-currency-groups,
    .navigation-06 .lang-currency-groups {
        border-top:1px solid #ebebeb
    }

    .wrapper-navigation .header-pc a {
        color: #3c3c3c
    }
    .wrapper-navigation .header-pc a:hover {
        color: #0688e2
    }
    .jas-mb-style {
      background-color: #ffffff
    }
    .site-nav .menu-mb-title {
        border-bottom:1px solid #ebebeb;
        color: #232323;
        background-color: #f8f8f8
    }
    .site-nav .menu-lv-1 {
        border-bottom:1px solid #ebebeb;
        
    }
    .site-nav .menu-lv-1>a {
        color: #232323
    }
    .site-nav .menu-lv-1:hover {
        background-color: #f8f8f8
    }
    .site-nav .menu-lv-1:hover>a {
        color: #0688e2
    }
    .site-nav .menu-lv-2 {
        border-bottom:1px solid #ebebeb
    }
    .site-nav .menu-lv-2>a {
        color: #232323
    }
    .site-nav .menu-lv-2:hover>a {
        color: #232323
    }
    .site-nav .menu-lv-3>a {
        color: #3c3c3c
    }
    .site-nav .menu-lv-3:hover>a {
        color: #0688e2
    }
    .site-nav .sub-menu-mobile {
      background-color: #ffffff;
    }
    .site-nav .mega-menu .mega-banner .title,
    .site-nav .mega-menu .mega-banner .product-item .btn {
        color: #232323
    }
    .site-nav .mega-menu .mega-banner .product-item .btn {
        border-bottom-color: rgba(35, 35, 35, 0.7)
    }
    .site-nav .d-md-flex .col-left .menu-lv-2 a {
        border-bottom:1px solid #ebebeb
    }
    
    .wrapper_header_02 .mb-area .free-shipping-text,
    .wrapper_header_02 .mb-area .wishlist,
    .wrapper_header_02 .mb-area .acc-links {
        color: #3c3c3c
    }
    .wrapper_header_02 .mb-area .free-shipping-text:hover,
    .wrapper_header_02 .mb-area .wishlist:hover,
    .wrapper_header_02 .mb-area .acc-links:hover {
        color: #0688e2
    }
    .wrapper_header_02 .mb-area .header-bottom-left .shipping-text {
        border-top:1px solid #ebebeb
    }
    .wrapper_header_02 .customer-links svg{
      display: none;
    }
    .wrapper_header_03 .header-pc .header-panel-top .left-groups {
        border-top:1px solid #ebebeb
    }
    .wrapper_header_03 .header-pc .social-icons a {
        color: #232323 !important
    }
    .wrapper_header_04 .appentMb .acc-links,
    .wrapper_header_04 .appentMb .wishlist {
        color: #3c3c3c
    }
    .wrapper_header_04 .appentMb .acc-links:hover,
    .wrapper_header_04 .appentMb .wishlist:hover {
        color: #0688e2
    }
    .wrapper_header_04 .appentMb .lang-currency-groups {
        border-top:1px solid #ebebeb
    }
    .wrapper_header_supermarket .fb-like-code a span {
        color: #232323
    }
    .wrapper_header_supermarket .header-links a,
    .wrapper_header_supermarket .info-store,
    .wrapper_header_supermarket .info-store .info-text a,
    .wrapper_header_supermarket append-mb,
    .wrapper_header_supermarket .append-mb a {
        color: #3c3c3c
    }
    .navigation-08 .header-bottom-right .help-item a {
        color: #3c3c3c !important;
    }

    .wrapper_header_supermarket .header-links a:hover,
    .wrapper_header_supermarket .info-store .info-text a:hover,
    .wrapper_header_supermarket .append-mb a:hover {
        color: #0688e2
    }
    .wrapper_header_supermarket .wrapper-navigation .lang-currency-groups {
        border-top:1px solid #ebebeb
    }
    .wrapper_header_supermarket .vertical-menu .menu-lv-1>a {
        color: #3c3c3c
    }
    .wrapper_header_supermarket.enable_vertical_menu .vertical-menu .menu-lv-1>a {
        color: #232323
    }
}
@media (max-width: 991px) {
  
}

@media (min-width: 768px) {
  
}

@media (min-width: 992px) {
    
}

@media (min-width: 1200px) {
  
  
  
    .animate-scale {
        position: relative;
        display: block;
        overflow: hidden
    }

    .animate-scale:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #232323;
        overflow: hidden;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";
        filter: alpha(opacity=0);
        z-index: 2;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease
    }

    .animate-scale img {
        -webkit-transition: all 0.7s ease;
        -moz-transition: all 0.7s ease;
        -ms-transition: all 0.7s ease;
        -o-transition: all 0.7s ease;
        transition: all 0.7s ease
    }

    .animate-scale:hover img {
        -moz-transform: scale3d(1.1, 1.1, 1);
        -o-transform: scale3d(1.1, 1.1, 1);
        -ms-transform: scale3d(1.1, 1.1, 1);
        -webkit-transform: scale3d(1.1, 1.1, 1);
        transform: scale3d(1.1, 1.1, 1)
    }

    .animate-scale:hover:before {
        overflow: visible;
        opacity: .3;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=30)";
        filter: alpha(opacity=30)
    }

    .animate-hvr:hover:before {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
        filter: alpha(opacity=100)
    }
    
    .quickSearchResultsWrap .item-swatch li label img {
      
    }
    .search-open .header-default .nav-search .close-search {
      color: #ffffff
    }
    .site-nav .no-mega-menu li+li>a {
        border-top:1px solid #ebebeb
    }
a.product-grid-image > picture > img.lazyload::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    background-size: 200% 100%; /* Adjust the size based on your preference */
    animation: shimmer 1.5s infinite linear;
}

@keyframes shimmer {
    to {
        background-position: 200% 0;
    }
}
    
    .product-item .product-grid-image:before {
      @include filled_obj();
      background-color: rgba(255, 255, 255, .7);
      @include opacity(0);
      @include transition(opacity .5s ease);
      visibility: hidden;
      z-index: 1;
    }
    
    
  .product-item .product-top:hover .images-one {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=0)";
    filter: alpha(opacity=0);
  }
  .product-item .product-top:hover .images-two {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
    filter: alpha(opacity=100);
  }
  .product-item .product-top:hover .product-des {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
  }
  
  .product-item .product-top:hover .product-grid-image:before {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
  }
  
  .product-item .inner-top:hover .wrapper-compare .compare-button {
    opacity: 1;
    visibility: visible;
  }
    
    
    .relate-verticle .related-products .products-grid .grid-item .product-details .shopify-product-reviews-badge {
        min-height: 29px;
    }
    
    .translate-header #dropdown-cart .mini-products-list {
        
    }
    
    .search-open .header-lang-style2 .search-form .close-search {
      color: #ffffff
    }
}
